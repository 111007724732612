import { css } from 'styled-components'

export default css`
  body,
  html {
    height: 100%;
    font-family: @font-family;
    background-color: ${(props) => props.theme.bgColor};
  }

  #__next {
    min-height: 100%;
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  /*
   * ANT: fix
   */
  .ant-badge-count {
    font-weight: 400;
    font-size: 10px;
    z-index: 10;
  }

  /*
   * ANT: adds background colour on every odd row
   */
  .ant-table-tbody .ant-table-row:nth-child(odd) {
    /* background-color: @background-color-light; */
  }

  /*
   * ANT: prevents checkbox from being round
   */
  .ant-checkbox .ant-checkbox-inner {
    /* border-radius: @border-radius-sm; */
  }

  /*
   * ANT: prevents form items to have a margin bottom
   * on the last element
   */
  .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .ant-popover-buttons {
    button {
      /*
       * Probably a bug with Ant Design as it should take the
       * sm version of the text to display well.
       */
      font-size: @font-size-sm;
      padding: 0px 15px;
    }
  }

  @media (min-width: @screen-4k) {
    .ant-select-item {
      font-size: 1.31rem;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
`
