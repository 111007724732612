import styled from 'styled-components'

import CommonLayout from '@@/Layout'

const getPanelPosition = (position: string) => {
  if (position === 'left') {
    return 'flex-start'
  }

  if (position === 'right') {
    return 'flex-end'
  }

  return 'center'
}

const Layout = styled(CommonLayout)`
  min-height: 100vh;
  display: flex;
  align-items: ${(props) => getPanelPosition(props?.theme?.panel?.position)};
  justify-content: center;
  padding: @padding-lg;
  background-color: ${(props) => props.theme.bgColor};
  color: ${(props) => props.theme.textColor};

  @media (max-width: @screen-xs) {
    align-items: center;
    padding: 0;
  }
`

export default Layout
