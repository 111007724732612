import Home from '~/containers/Home'

import Layout from '@/Layout'

const HomePage = (): JSX.Element => (
  <Layout>
    <Home />
  </Layout>
)

export default HomePage
