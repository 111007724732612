/* eslint-disable no-lonely-if */
import { auth, useUser } from '@coding4tomorrow/c4t-next-core'
import { notification } from 'antd'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import usePrefix from '~/hooks/usePrefix'
import useProjectInfo from '~/hooks/useProjectInfo'

import FullPageLoader from '@@/FullPageLoader'

const Home = () => {
  const prefix = usePrefix()
  const router = useRouter()

  const { user } = useUser()

  const { token } = auth

  const { closestStartingEvent, type, isTicketOfficeActive } = useProjectInfo()

  const { projectId, site } = router.query

  useEffect(() => {
    if (projectId || site) {
      if (user && !closestStartingEvent) {
        notification.open({
          message: 'Unexpected error',
          description: 'This project has no events',
          type: 'error',
        })
      }

      if (user) {
        router.push(`${prefix}/event/${closestStartingEvent}`)
      } else {
        if (token) {
          if (type === 'private') {
            router.push(`${prefix}/denied`)
          } else if (type === 'public' && isTicketOfficeActive) {
            router.push(`${prefix}/ticket-office`)
          }
        } else {
          if (type === 'private') {
            router.push(`${prefix}/denied`)
          } else if (type === 'public') {
            if (isTicketOfficeActive) {
              router.push(`${prefix}/ticket-office`)
            } else {
              router.push(`${prefix}/event/${closestStartingEvent}`)
            }
          }
        }
      }
    }
  }, [
    router,
    token,
    user,
    prefix,
    projectId,
    site,
    closestStartingEvent,
    type,
    isTicketOfficeActive,
  ])

  return <FullPageLoader />
}

export default Home
