/* eslint-disable @next/next/no-css-tags */
import { Layout as AntLayout } from 'antd'
import Head from 'next/head'
import styled, { createGlobalStyle, css } from 'styled-components'

import useUserStage from '~/hooks/useUserStage'

import { UserStageType } from '~/types/user'

import global from './styles/global'
import print from './styles/print'

const GlobalStyles = createGlobalStyle`
  ${global};
  ${print};
`
interface ContainerProps {
  $backgroundImage: string
}
const Container = styled(AntLayout)<ContainerProps>`
  min-height: 100vh;
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
    background: url(${$backgroundImage}) no-repeat 50% 50%;
    background-size: cover;
  `}
`

interface LayoutProps {
  children: React.ReactNode
}
const Layout = ({ children, ...props }: LayoutProps) => {
  const { stage }: UserStageType = useUserStage()

  return (
    <Container
      $backgroundImage={stage?.preEventBackground}
      {...props}
    >
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/favicon/manifest.json"
        />
      </Head>
      <GlobalStyles />
      {children}
    </Container>
  )
}

export default Layout
